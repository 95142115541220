<template>
  <div>
    <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="dark"
        :header="pengaduan"
        text="Pengajuan Penebangan"
      >
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
              <CIcon name="cil-bell"/>
            </template>
            <CDropdownItem to="/pengajuan">Lihat Data</CDropdownItem>
            <!-- <CDropdownItem to="/pengaduan/add">Tambah Data</CDropdownItem> -->
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="success" :header="pohon" text="Pohon">
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
              <CIcon name="cil-lightbulb"/>
            </template>
            <CDropdownItem to="/pohon">Lihat Data</CDropdownItem>
            <CDropdownItem to="/pohon/add">Tambah Data</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="success"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" :header="taman" text="Taman">
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
              <CIcon name="cil-lightbulb"/>
            </template>
            <CDropdownItem to="/taman">Lihat Data</CDropdownItem>
            <CDropdownItem to="/taman/add">Tambah Data</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[1, 18, 9, 17, 34, 22, 11]"
            point-hover-background-color="info"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="danger"
        :header="informasipohon"
        text="Jenis Pohon"
      >
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
             <CIcon name="cil-bell"/>
            </template>
            <CDropdownItem to="/informasi">Lihat Data</CDropdownItem>
            <CDropdownItem to="/informasi/add">Tambah Data</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader> SIPPohon -  Dinas PUPR Kota Pontianak 
            <span class="float-right">
              <CDropdown
                color="warning p-0 px-2"
                placement="bottom-end"
              >
                <template #toggler-content>
                  Jenis Data
                </template>
                <CDropdownItem @click="loadData('pohon')">Pohon</CDropdownItem>
                <CDropdownItem @click="loadData('taman')">Taman</CDropdownItem>
              </CDropdown>
                
            
            </span>
          </CCardHeader>
          <CCardBody class="p-0">
            <gmap-map :zoom="12" :center="center" ref="mapRef" style="width: 100%; height: 600px" 
                >
              <gmap-marker
                :key="index"
                v-for="(m, index) in locationMarkers"
                :position="m.position"
                @click="openMarker(m.id)"
              >
                <gmap-info-window
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === m.id"
                >
                  <div><h6 class="p-0">{{ m.name }}</h6></div>
                  <hr/>
                  <div v-html="setTable(m.description)"></div>
                </gmap-info-window>
              </gmap-marker>
            </gmap-map>
            <CSpinner v-show="mySpinner" color="warning" style="position:absolute;left: 50%;top:50%;z-index:9000"/>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Traffic</h4>
            <div class="small text-muted">November 2017</div>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <CButton color="primary" class="float-right">
              <CIcon name="cil-cloud-download" />
            </CButton>
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-secondary"
                v-for="(value, key) in ['Day', 'Month', 'Year']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="selected = value"
              >
                {{ value }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <MainChartExample style="height: 300px; margin-top: 40px" />
      </CCardBody>
      <CCardFooter>
        <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Visits</div>
            <strong>29.703 Users (40%)</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="success"
              :value="40"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Unique</div>
            <strong>24.093 Users (20%)</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="info"
              :value="20"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Pageviews</div>
            <strong>78.706 Views (60%)</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="warning"
              :value="60"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">New Users</div>
            <strong>22.123 Users (80%)</strong>
            <CProgress
              class="progress-xs mt-2"
              :precision="1"
              color="danger"
              :value="80"
            />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Bounce Rate</div>
            <strong>Average Rate (40.15%)</strong>
            <CProgress class="progress-xs mt-2" :precision="1" :value="40" />
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <WidgetsBrand /> -->
  </div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from './charts/index.js'
import MainChartExample from "./charts/MainChartExample";
// import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
// if (localStorage.getItem("jwt") == null || localStorage.getItem("jwt") == "") {
//   router.push({ path: "/login" });
// }
export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    // WidgetsDropdown,
    WidgetsBrand,
    CChartLineSimple, 
    CChartBarSimple
  },
  data() {
    return {
      mySpinner : false,
      pohon : "0",
      taman : "0",
      pengaduan : "0",
      informasipohon : "0",
      oksigen: "-",
      biomassa: "-",
      carbondioksida: "-",
      tinggi: "-",
      lebartajuk: "-",
      diameter: "-",
      options: {
        zoom: 12,
        center: {
          lat: 39.9995601,
          lng: -75.1395161
        },
        mapTypeId: "roadmap",
                
      },
      mapStyle: {
        styles: [
                  {
                      "featureType": "water",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#e9e9e9"
                          },
                          {
                              "lightness": 17
                          }
                      ]
                  },
                  {
                      "featureType": "landscape",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#f5f5f5"
                          },
                          {
                              "lightness": 20
                          }
                      ]
                  },
                  {
                      "featureType": "road.highway",
                      "elementType": "geometry.fill",
                      "stylers": [
                          {
                              "color": "#ffffff"
                          },
                          {
                              "lightness": 17
                          }
                      ]
                  },
                  {
                      "featureType": "road.highway",
                      "elementType": "geometry.stroke",
                      "stylers": [
                          {
                              "color": "#ffffff"
                          },
                          {
                              "lightness": 29
                          },
                          {
                              "weight": 0.2
                          }
                      ]
                  },
                  {
                      "featureType": "road.arterial",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#ffffff"
                          },
                          {
                              "lightness": 18
                          }
                      ]
                  },
                  {
                      "featureType": "road.local",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#ffffff"
                          },
                          {
                              "lightness": 16
                          }
                      ]
                  },
                  {
                      "featureType": "poi",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#f5f5f5"
                          },
                          {
                              "lightness": 21
                          }
                      ]
                  },
                  {
                      "featureType": "poi.park",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#dedede"
                          },
                          {
                              "lightness": 21
                          }
                      ]
                  },
                  {
                      "elementType": "labels.text.stroke",
                      "stylers": [
                          {
                              "visibility": "on"
                          },
                          {
                              "color": "#ffffff"
                          },
                          {
                              "lightness": 16
                          }
                      ]
                  },
                  {
                      "elementType": "labels.text.fill",
                      "stylers": [
                          {
                              "saturation": 36
                          },
                          {
                              "color": "#333333"
                          },
                          {
                              "lightness": 40
                          }
                      ]
                  },
                  {
                      "elementType": "labels.icon",
                      "stylers": [
                          {
                              "visibility": "off"
                          }
                      ]
                  },
                  {
                      "featureType": "transit",
                      "elementType": "geometry",
                      "stylers": [
                          {
                              "color": "#f2f2f2"
                          },
                          {
                              "lightness": 19
                          }
                      ]
                  },
                  {
                      "featureType": "administrative",
                      "elementType": "geometry.fill",
                      "stylers": [
                          {
                              "color": "#fefefe"
                          },
                          {
                              "lightness": 20
                          }
                      ]
                  },
                  {
                      "featureType": "administrative",
                      "elementType": "geometry.stroke",
                      "stylers": [
                          {
                              "color": "#fefefe"
                          },
                          {
                              "lightness": 17
                          },
                          {
                              "weight": 1.2
                          }
                      ]
                  }
              ],
      },
      center: {
        lat: -0.060538, 
        lng: 109.353780,
      },
      openedMarkerID: null,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      window_open: false,
      currentMidx: null,  
      selected: "Month",
      tableItems: [
        {
          avatar: { url: "img/avatars/1.jpg", status: "success" },
          user: {
            name: "Yiorgos Avraamu",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "USA", flag: "cif-us" },
          usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Mastercard", icon: "cib-cc-mastercard" },
          activity: "10 sec ago",
        },
        {
          avatar: { url: "img/avatars/2.jpg", status: "danger" },
          user: {
            name: "Avram Tarasios",
            new: false,
            registered: "Jan 1, 2015",
          },
          country: { name: "Brazil", flag: "cif-br" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Visa", icon: "cib-cc-visa" },
          activity: "5 minutes ago",
        },
        {
          avatar: { url: "img/avatars/3.jpg", status: "warning" },
          user: { name: "Quintin Ed", new: true, registered: "Jan 1, 2015" },
          country: { name: "India", flag: "cif-in" },
          usage: { value: 74, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Stripe", icon: "cib-stripe" },
          activity: "1 hour ago",
        },
        {
          avatar: { url: "img/avatars/4.jpg", status: "" },
          user: { name: "Enéas Kwadwo", new: true, registered: "Jan 1, 2015" },
          country: { name: "France", flag: "cif-fr" },
          usage: { value: 98, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "PayPal", icon: "cib-paypal" },
          activity: "Last month",
        },
        {
          avatar: { url: "img/avatars/5.jpg", status: "success" },
          user: {
            name: "Agapetus Tadeáš",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Spain", flag: "cif-es" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Google Wallet", icon: "cib-google-pay" },
          activity: "Last week",
        },
        {
          avatar: { url: "img/avatars/6.jpg", status: "danger" },
          user: {
            name: "Friderik Dávid",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Poland", flag: "cif-pl" },
          usage: { value: 43, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Amex", icon: "cib-cc-amex" },
          activity: "Last week",
        },
      ],
      tableFields: [
        { key: "avatar", label: "", _classes: "text-center" },
        { key: "user" },
        { key: "country", _classes: "text-center" },
        { key: "usage", label:"Progress" },
        { key: "payment", label: "Icon", _classes: "text-center" },
        { key: "activity" },
      ],
      locations:'',
    };
  },
  mounted() {
    this.loadData('pohon');
    
  },

  // computed: {
  //   google: gmapApi,
  //   markers() {
  //     return this.locations.map(({ label, location: { lat, lon }, name, prev }) => ({
  //       label: {
  //         text: label,
  //         color: '#333',
  //         fontWeight: 'bold',
  //         fontSize: '30px'
  //       },
  //       position: {
  //         lat,
  //         lng: lon
  //       },
  //       name,
  //       prev,
  //       icon: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#28a745" width="44" height="44" viewBox="0 0 24 24"><path d="M12 3c2.131 0 4 1.73 4 3.702 0 2.05-1.714 4.941-4 8.561-2.286-3.62-4-6.511-4-8.561 0-1.972 1.869-3.702 4-3.702zm0-2c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z"/></svg>'
  //     }));
  //   }
  // },
  methods: {
    setTable(desc){
      var descArray = desc.split("#");
      var newDesc="<table border=0 width='320px' cellpadding='2'>";  
      for (let index = 0; index < descArray.length; index++) {
        const element = descArray[index];
        newDesc += "<tr>";
        var isiDesc = element.toString().split(":");
        for (let i2 = 0; i2 < isiDesc.length; i2++) {
          const element2 = isiDesc[i2];
          if(i2==0){
            newDesc +="<td>"+element2+"</td>";
          }
          else{
            newDesc +="<td> : "+element2+"</td>";
          }
          
        }   
        newDesc += "</tr>"; 
      }
      newDesc += "</table>";
      return newDesc;
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    openMarker(id) {
      axios
        .get(
          process.env.VUE_APP_BASE_URL+"markers/json/"+jenis
        )
        .then((response) => {
          this.locPlaces = response.data;
          Object.values(this.locPlaces).forEach((item) =>
            this.addLocationMarker(
              item.id,
              item.name,
              '',
              item.lat,
              item.lng,
              ''
            )
          );
          this.mySpinner = false;
          

          // for (property in Object.values(this.locPlaces)){
          //   // this.form.examiner_id.push(this.locPlaces[property].id);
          //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
          // }
        });
        axios
        .get(
          process.env.VUE_APP_BASE_URL+"marker/"+id
        )
        .then((response) => {
          this.pohon = response.data.pohon;
          this.taman = response.data.taman;
          this.pengaduan = response.data.pengaduan;
          this.informasipohon = response.data.informasipohon;
          

          // for (property in Object.values(this.locPlaces)){
          //   // this.form.examiner_id.push(this.locPlaces[property].id);
          //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
          // }
        });
      this.openedMarkerID = id;
    },
    addLocationMarker(id, nama, description,latt, long, url) {
      // if (this.existingPlace) {
      // const marker = {
      //   lat: this.existingPlace.geometry.location.lat(),
      //   lng: this.existingPlace.geometry.location.lng()
      // };

      const marker = {
        lat: +latt,
        lng: +long,
      };
      this.locationMarkers.push({ position: marker, id: id, name: nama, description:description, url:url });
      this.locPlaces.push(this.existingPlace);
      // this.center = marker;
      // this.existingPlace = null;
      // }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    loadData: function (jenis) {
      this.mySpinner = true;
      this.locationMarkers = [];
      this.locPlaces = []; 
      axios
        .get(
          process.env.VUE_APP_BASE_URL+"markers/json/"+jenis
        )
        .then((response) => {
          this.locPlaces = response.data;
          Object.values(this.locPlaces).forEach((item) =>
            this.addLocationMarker(
              item.id,
              item.name,
              '',
              item.lat,
              item.lng,
              ''
            )
          );
          this.mySpinner = false;
          

          // for (property in Object.values(this.locPlaces)){
          //   // this.form.examiner_id.push(this.locPlaces[property].id);
          //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
          // }
        });
        axios
        .get(
          process.env.VUE_APP_BASE_URL+"dashboardstat"
        )
        .then((response) => {
          this.pohon = response.data.pohon;
          this.taman = response.data.taman;
          this.pengaduan = response.data.pengaduan;
          this.informasipohon = response.data.informasipohon;
          

          // for (property in Object.values(this.locPlaces)){
          //   // this.form.examiner_id.push(this.locPlaces[property].id);
          //   this.addLocationMarker(this.locPlaces[property].lattitude, this.locPlaces[property].longitude);
          // }
        });
    },
  },
};
</script>
<style scoped>

  #map {
    height: 500px;
    width: 100%;
    margin: 0 auto;
  }
  
  .list-inline-item {
    cursor: pointer;
  }
  
  </style>
